import React, {Component} from "react";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";

//Import Icons
//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";


// importar imágenes
import desarrollo from '../../assets/images/platzi/contenido.png';
import contenido from '../../assets/images/platzi/contenido.png';
import diseño from '../../assets/images/platzi/diseño.png';
import marketing from '../../assets/images/platzi/marketing.png';
import negocios from '../../assets/images/platzi/negocios.png';
import dataScience from '../../assets/images/platzi/data-science.png';
import crypto from '../../assets/images/platzi/crypto.png';
import ingles from '../../assets/images/platzi/ingles.png';


class Courses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courses: [
                {
                    icon: desarrollo,
                    title: "Desarrollo de Software y Programación",
                    link: "#",
                    info: "Domina lenguajes de programación como Python, JavaScript y más. Aprende a desarrollar aplicaciones backend, frontend y móviles."
                },
                {
                    icon: diseño,
                    title: "Diseño y UX/UI",
                    link: "#",
                    info: "Crea experiencias digitales impactantes con diseño visual, prototipado y usabilidad para productos innovadores."
                },
                {
                    icon: marketing,
                    title: "Marketing Digital y Growth Hacking",
                    link: "#",
                    info: "Desarrolla estrategias de SEO, redes sociales y analítica para escalar negocios y conquistar el mundo digital."
                },
                {
                    icon: negocios,
                    title: "Negocios y Emprendimiento",
                    link: "#",
                    info: "Aprende a crear modelos de negocio, gestionar finanzas y captar inversión para transformar tus ideas en startups exitosas."
                },
                {
                    icon: dataScience,
                    title: "Inteligencia Artificial y Ciencia de Datos",
                    link: "#",
                    info: "Descubre el potencial de la IA y el análisis de datos. Aprende a resolver problemas complejos con machine learning y data science."
                },
                {
                    icon: crypto,
                    title: "Blockchain y Web3",
                    link: "#",
                    info: "Explora el futuro descentralizado con blockchain, contratos inteligentes y aplicaciones en el ecosistema Web3."
                },
                {
                    icon: contenido,
                    title: "Producción Audiovisual y Contenidos",
                    link: "#",
                    info: "Domina herramientas para crear videos, podcasts y contenido visual cautivador con storytelling efectivo."
                },
                {
                    icon: ingles,
                    title: "Inglés",
                    link: "#",
                    info: "Mejora tu inglés técnico y profesional para destacar en proyectos globales, entrevistas y reuniones internacionales."
                },
            ],

        };
    }

    toggleInfo = (index) => {
        this.setState((prevState) => {
            const courses = [...prevState.courses];
            courses[index].expanded = !courses[index].expanded;
            return {courses};
        });
    };

    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="courses-section">
                    <Container>
                        {/* render Section title */}
                        <SectionTitle
                            title="Más de 1,700 cursos para transformar tu futuro"
                            desc=" En Platzi encontrarás una biblioteca con más de 1,700 cursos diseñados para llevarte al siguiente nivel en tu carrera. Desde desarrollo de software hasta negocios y marketing, sus escuelas te guiarán en cada paso de tu aprendizaje. Aprende de expertos, accede a contenido actualizado y construye habilidades que te harán destacar en la industria."
                        />

                        <Row>
                            {this.state.courses.map((course, key) => (
                                <Col lg={3} md={4} xs={12} key={key} className="mt-4 pt-2">
                                    <Card
                                        className="features feature-primary explore-feature border-0 rounded text-center">
                                        <CardBody className="py-5">


                                            <img
                                                src={course.icon}
                                                style={{height: 65, width: 65}}
                                                className="avatar avatar-small client-image rounded shadow"
                                                alt=""
                                            />
                                            <div className="content mt-3" style={{maxHeight: '95px'}}>
                                                <h5>
                                                    <Link to={course.link} className="title text-dark">
                                                        {course.title}
                                                    </Link>
                                                </h5>
                                                <a className="text-muted small"
                                                   onClick={() => this.toggleInfo(key)}>
                                                    Ver más
                                                </a>
                                                {course.expanded && (
                                                    <p className="mt-3 text-muted">{course.info}</p>
                                                )}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}

                            <Col xs={12}>
                                <div className="text-center mt-4 pt-2">
                                    <a className="btn btn-primary">
                                        Ver más{" "}
                                        <i className="uil uil-arrow-right align-middle"></i>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Courses;
