import React, {useState} from 'react';
import {Alert, Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row} from 'reactstrap';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {sendContactInfo} from '../../components/Shared/services/ContactService';
import cta from '../../assets/images/crypto/bg.jpg';
import cta1 from '../../assets/images/crypto/cta.png';

const Cta = () => {
    const [successMsg, setSuccessMsg] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .min(2, "El nombre debe tener al menos 2 caracteres.")
                .required("El nombre es obligatorio."),
            email: Yup.string()
                .email("Por favor ingresa un correo electrónico válido.")
                .required("El correo electrónico es obligatorio."),
            phone: Yup.string()
                .matches(/^\d{10}$/, "Por favor ingresa un número de teléfono válido de 10 dígitos.")
                .required("El teléfono es obligatorio."),
        }),
        onSubmit: (values, {resetForm}) => {
            sendContactInfo(values)
                .then(() => {
                    setSuccessMsg(true);
                    setTimeout(() => setSuccessMsg(false), 5000);
                    resetForm();
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        },
    });

    return (
        <section className="section pb-0" style={{background: `url(${cta}) center`}}>
            <div className="bg-overlay bg-primary bg-gradient" style={{opacity: '0.7'}}></div>
            <Container>
                <Row className="justify-content-center">
                    <Col xs={12}>
                        <div className="section-title text-center mb-4 pb-2">
                            <h4 className="title text-white title-dark mb-4 text-capitalize">¿Quieres ser parte de COINNOVA?</h4>
                            <p className="text-light para-dark para-desc mx-auto">Estamos aquí para resolver tus dudas y acompañarte en el camino hacia la innovación tecnológica.</p>
                        </div>
                        <Container className="py-5">
                            <Row className="justify-content-center">
                                <Col md={8} lg={6}>
                                    <Card className="shadow border-0">
                                        <CardBody className="text-center">
                                            {successMsg && (
                                                <Alert color="success" className="text-center">
                                                    ¡Gracias por registrarte! Te contactaremos pronto.
                                                </Alert>
                                            )}

                                            <Form onSubmit={formik.handleSubmit}>
                                                <Label for="name" className="sr-only">
                                                    Datos de contacto:
                                                </Label>
                                                <Input
                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    placeholder="Ingresa tu nombre completo"
                                                    className="form-control mb-3"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                    invalid={formik.touched.name && formik.errors.name ? true : false}
                                                />
                                                {formik.touched.name && formik.errors.name && (
                                                    <FormFeedback>{formik.errors.name}</FormFeedback>
                                                )}

                                                <Input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    placeholder="Ingresa tu correo electrónico"
                                                    className="form-control mb-3"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                    invalid={formik.touched.email && formik.errors.email ? true : false}
                                                />
                                                {formik.touched.email && formik.errors.email && (
                                                    <FormFeedback>{formik.errors.email}</FormFeedback>
                                                )}

                                                <Input
                                                    id="phone"
                                                    name="phone"
                                                    type="text"
                                                    placeholder="Ingresa tu número de teléfono"
                                                    className="form-control mb-3"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.phone}
                                                    invalid={formik.touched.phone && formik.errors.phone ? true : false}
                                                />
                                                {formik.touched.phone && formik.errors.phone && (
                                                    <FormFeedback>{formik.errors.phone}</FormFeedback>
                                                )}

                                                <Button type="submit" color="primary" block>
                                                    ¡Quiero saber más!
                                                </Button>
                                            </Form>

                                            <div className="mt-4">
                                                <p className="text-muted mb-0">
                                                    ¿Tienes dudas? Contáctanos en cualquier momento a través de
                                                    <br/>
                                                    <a href="mailto:info@innovatex.com" className="ml-1 text-primary">
                                                        info@innovatex.com
                                                    </a>
                                                </p>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                        <div className="mt-4 pt-2">
                            <img src={cta1} className="img-fluid d-block mx-auto" alt=""/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Cta;