import React, {Component} from "react";
import {Card, CardBody, Col, Container, Row,} from "reactstrap";

// Modal Video
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

//Import Icons
import FeatherIcon from "feather-icons-react";

//Import Images

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen1: false,
            isOpen: false,
            branchName: "U.S.A",
            location:
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin",
            branches: [
                {
                    title: "U.S.A.",
                    desc: "Long Street, WS, US",
                    location:
                        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin",
                },
                {
                    title: "China",
                    desc: "Wuhan, China",
                    location:
                        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin",
                },
                {
                    title: "India",
                    desc: "Channai, India",
                    location:
                        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin",
                },
            ],
        };
        this.openModal = this.openModal.bind(this);
        this.openModalMap.bind(this);
    }

    openModal() {
        this.setState({isOpen: true});
    }

    openModalMap = (branchName, location) => {
        this.setState({
            isOpen1: !this.state.isOpen1,
            branchName: branchName,
            location: location,
        });
    };


    render() {
        return (
            <Container className="mt-5">
                <Row className="text-center mb-5">
                    <Col>
                        <h1 className="display-4 font-weight-bold">Metodología de Formación 4.0</h1>
                        <p className="text-muted">
                            Nuestra misión es democratizar el uso de tecnología en sectores intermedios y combatir
                            la "Paradoja Tech".
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col md={6}>
                        <Card className="mb-4">
                            <CardBody>
                                <FeatherIcon icon="book-open" className="icon-md text-primary mb-3"/>
                                <h5 className="font-weight-bold">Etapa Teórica</h5>
                                <p className="text-muted">
                                    La primera etapa combina el uso de la plataforma
                                    educativa <strong>Platzi</strong> con mentorías personalizadas de nuestro
                                    equipo.
                                    Aquí los estudiantes adquieren conocimientos técnicos en programación, diseño y
                                    más, asegurando una base sólida.
                                </p>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md={6}>
                        <Card className="mb-4">
                            <CardBody>
                                <FeatherIcon icon="code" className="icon-md text-success mb-3"/>
                                <h5 className="font-weight-bold">Etapa Práctica</h5>
                                <p className="text-muted">
                                    En la segunda etapa, los estudiantes aplican lo aprendido colaborando en proyectos
                                    freelance e iniciativas propias o proyectos reales, como <strong>Yaku</strong> o <strong>Go</strong>.
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <h3 className="font-weight-bold text-center mt-5">El Modelo Circular de Innovatex</h3>
                        <p className="text-muted text-center mb-5">
                            Captamos talento tech, lo formamos con un enfoque integral, y los conectamos con
                            oportunidades en proyectos propios,
                            freelance o empresas aliadas. Nuestra economía circular no solo crea talento altamente
                            capacitado, sino que valida
                            productos y servicios con un coste operativo optimizado.
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <Card className="text-center">
                            <CardBody>
                                <FeatherIcon icon="users" className="icon-md text-info mb-3"/>
                                <h5 className="font-weight-bold">Captación de Talento</h5>
                                <p className="text-muted">
                                    Identificamos y atraemos a programadores en etapa temprana para integrarlos en
                                    nuestro ecosistema.
                                </p>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card className="text-center">
                            <CardBody>
                                <FeatherIcon icon="briefcase" className="icon-md text-warning mb-3"/>
                                <h5 className="font-weight-bold">Colaboración en Proyectos</h5>
                                <p className="text-muted">
                                    Los estudiantes trabajan en proyectos Freelance o proyectos reales de Innovatex, como Yaku y Go.
                                </p>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card className="text-center">
                            <CardBody>
                                <FeatherIcon icon="send" className="icon-md text-danger mb-3"/>
                                <h5 className="font-weight-bold">Modelo de Staff</h5>
                                <p className="text-muted">
                                    Tras adquirir experiencia, los talentos son conectados con empresas aliadas bajo
                                    nuestro modelo de staff.
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}


export default About;
