import { 
    logisticsHomeData, 
    partnersData, 
    servicesData, 
    reviews, 
    logisticsBlogs, 
    featureArray, 
    testimonials, 
    pricings, 
    facilities, 
    agencyBlogsData,
    agencyFeatureArray,
    appsFeatures,
    appsPricings,
    classicFeatures,
    studioProcessesData,
    marketingCounters,
    enterpriceFeatures,
    enterpricePricings,
    enterpriceBlogs,
    serviceFeatures,
    serviceCounters,
    paymentsProcess,
    paymentsClientsData,
    itSolutionProgress,
    itSolutionWhatwedo,
    developerKeyfeatures,
    businessFeatures,
    hotelClientsData,
    hotelRoomsData,
    hotelBlogsData,
    hotelFeaturesData,
    constructionMembers,
    constructionNewsData,
    emailInboxFeatures,
    travelBenefitData,
    coursesData,
    candidatesData,
    insuranceFeaturesData,
    personalBlogsData,
    eventPricingsData,
    onepageFeaturesData
} from "./landing";

export { 
    logisticsHomeData, 
    partnersData, 
    servicesData, 
    reviews, 
    logisticsBlogs, 
    featureArray, 
    testimonials, 
    pricings, 
    facilities, 
    agencyBlogsData,
    agencyFeatureArray,
    appsFeatures,
    appsPricings,
    classicFeatures,
    studioProcessesData,
    marketingCounters,
    enterpriceFeatures,
    enterpricePricings,
    enterpriceBlogs,
    serviceFeatures,
    serviceCounters,
    paymentsProcess,
    paymentsClientsData,
    itSolutionProgress, 
    itSolutionWhatwedo,
    developerKeyfeatures,
    businessFeatures,
    hotelClientsData,
    hotelRoomsData,
    hotelBlogsData,
    hotelFeaturesData,
    constructionMembers,
    constructionNewsData,
    emailInboxFeatures,
    travelBenefitData,
    coursesData,
    candidatesData,
    insuranceFeaturesData,
    personalBlogsData,
    eventPricingsData,
    onepageFeaturesData
 };