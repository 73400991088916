import React, {Component} from "react";
import {Container, Row} from "reactstrap";

//Import Components
import SectionTitle from "../../../src/components/Shared/SectionTitle.js";
import ProcessBox from "./ProcessBox";
//Import Images
import img1 from "../../../src/assets/images/crypto/1.png";
import img2 from "../../../src/assets/images/crypto/2.png";
import img3 from "../../../src/assets/images/crypto/3.png";
import img4 from "../../../src/assets/images/crypto/4.png";

class Process extends Component {
    state = {
        processArray: [
            {
                title: "Impacto social",
                icon: img1,
                desc: "Contribuye al desarrollo de talento humano en tecnología",
                link: ""
            },
            {
                title: "Acceso a innovación",
                icon: img2,
                desc: "Ecosistema que promueve la democratización tecnológica",
                link: ""
            },
            {
                title: "Sostenibilidad",
                icon: img3,
                desc: "Economia circular para una mayor optimizacion de recursos",
                link: "",
            },
            {
                title: "Visión a largo plazo",
                icon: img4,
                desc: "Sé parte de la comunidad que construye el futuro.",
                link: "",
            },
        ],
    };

    render() {
        return (
            <React.Fragment>
                <Container className="mt-100 mt-60">
                    {/* Section title */}
                    <SectionTitle
                        title="Como Funciona ?"
                        desc=" NOVA funciona como una herramienta que impulsa soluciones innovadoras mientras fomenta el desarrollo y crecimiento de sus participantes"
                    />

                    <Row>
                        {/* Process Box */}
                        <ProcessBox data={this.state.processArray}/>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default Process;
