import React, {Component} from 'react'
import {Col, Container, Row} from 'reactstrap';

import bg from '../../assets/images/crypto/bg.jpg';

export default class Section extends Component {

    scrollToSection = () => {
        console.log("scrolling how-its-work-section");
        const section = document.getElementById("how-its-work-section");
        console.log('section', section)
        if (section) {
            section.scrollIntoView({behavior: "smooth"});
        }
    };

    render() {
        return (
            <React.Fragment>
                <section className="bg-home d-flex align-items-center"
                         style={{background: `url(${bg}) top `}}
                >
                    <Container>
                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <div className="title-heading text-center">
                                    <h4 className="heading fw-bold text-white title-dark mb-3"
                                        style={{'text-transform': 'capitalize'}}>Un token para transformar la
                                        tecnología.</h4>
                                    <h5 className="para-desc mx-auto text-light title-dark">Democratizando la tecnología
                                        para impactar vidas.</h5>
                                    <div className="mt-4 pt-2">
                                        <button className="btn btn-primary" onClick={this.scrollToSection}>Como
                                            Funciona?
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        )
    }
}
