import React, {useState} from "react";
import {Alert, Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row} from "reactstrap";
import {useFormik} from "formik";
import * as Yup from "yup";
import {sendContactInfo} from "../../components/Shared/services/ContactService";

const Cta = () => {
    const [successMsg, setSuccessMsg] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            message: "", // Añadir campo de mensaje
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .min(2, "El nombre debe tener al menos 2 caracteres.")
                .required("El nombre es obligatorio."),
            email: Yup.string()
                .email("Por favor ingresa un correo electrónico válido.")
                .required("El correo electrónico es obligatorio."),
            phone: Yup.string()
                .matches(/^\d{10}$/, "Por favor ingresa un número de teléfono válido de 10 dígitos.")
                .required("El teléfono es obligatorio."),
            message: Yup.string(), // Añadir validación para el campo de mensaje
        }),
        onSubmit: (values, {resetForm}) => {
            values.identifier = 'formacion_4_0';
            sendContactInfo(values)
                .then(() => {
                    setSuccessMsg(true);
                    setTimeout(() => setSuccessMsg(false), 5000);
                    resetForm();
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        },
    });

    return (
        <Container className="py-5">
            <Row className="justify-content-center">
                <Col md={8} lg={6}>
                    <Card className="shadow border-0">
                        <CardBody className="text-center">
                            <h2 className="font-weight-bold mb-4">¡Transforma tu futuro hoy!</h2>
                            <p className="text-muted mb-4">
                                Únete a nuestro programa Formación 4.0 y lleva tus habilidades al siguiente nivel.
                                Aprende de expertos,
                                colabora en proyectos reales y forma parte de la economía digital.
                            </p>

                            {successMsg && (
                                <Alert color="success" className="text-center">
                                    ¡Gracias por registrarte! Te contactaremos pronto.
                                </Alert>
                            )}

                            <Form onSubmit={formik.handleSubmit}>
                                <Label for="name" className="sr-only">
                                    Datos de contacto:
                                </Label>
                                <Input
                                    id="name"
                                    name="name"
                                    type="text"
                                    placeholder="Ingresa tu nombre"
                                    className="form-control mb-3"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                    invalid={formik.touched.name && formik.errors.name ? true : false}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <FormFeedback>{formik.errors.name}</FormFeedback>
                                )}

                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    placeholder="Ingresa tu correo electrónico"
                                    className="form-control mb-3"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    invalid={formik.touched.email && formik.errors.email ? true : false}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <FormFeedback>{formik.errors.email}</FormFeedback>
                                )}

                                <Input
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    placeholder="Ingresa tu número de teléfono"
                                    className="form-control mb-3"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.phone}
                                    invalid={formik.touched.phone && formik.errors.phone ? true : false}
                                />
                                {formik.touched.phone && formik.errors.phone && (
                                    <FormFeedback>{formik.errors.phone}</FormFeedback>
                                )}

                                <Input
                                    id="message"
                                    name="message"
                                    type="textarea"
                                    placeholder="Ingresa un mensaje corto (opcional)"
                                    className="form-control mb-3"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.message}
                                />

                                <Button type="submit" color="primary" block>
                                    ¡Quiero saber más!
                                </Button>
                            </Form>

                            <div className="mt-4">
                                <p className="text-muted mb-0">
                                    ¿Tienes dudas? Contáctanos en cualquier momento a través de
                                    <br/>
                                    <a href="mailto:info@innovatex.com" className="ml-1 text-primary">
                                        info@innovatex.com
                                    </a>
                                </p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Cta;