import React, {useEffect} from "react";
import {boldApiKey} from "../../common/data/landing";

const BoldButton = ({paymentData}) => {
    useEffect(() => {
        console.log("Renderizando botón Bold", paymentData);
        // Cargar el script de Bold dinámicamente si aún no existe
        if (!document.getElementById("bold-payment-script")) {
            const script = document.createElement("script");
            script.id = "bold-payment-script";
            script.src = "https://checkout.bold.co/library/boldPaymentButton.js";
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                if (window.Bold) {
                    window.Bold.render();
                }
            };
        } else {
            // Renderizar el botón si el script ya está cargado
            if (window.Bold) {
                window.Bold.render();
            }
        }
    }, [paymentData]); // Efecto se ejecuta cuando paymentData cambia

    return (
        <div id="bold-button-container" style={{marginTop: "20px", display: "inline-flex"}}>
            <script
                id="data-bold-button"
                data-bold-button
                data-api-key={boldApiKey}
                data-description={paymentData.description}
                data-redirection-url={paymentData.return_url}
                data-render-mode="embedded"
                data-currency={paymentData.currency}
                data-amount={paymentData.amount}
                data-image-url="https://res.cloudinary.com/dngnh6ir8/image/upload/v1736855059/m62fygtdsi2epr4lkvhs.png"
                data-order-id={paymentData.reference}
                data-reference={paymentData.reference}
                data-integrity-signature={paymentData.signature}
                {...(paymentData.userData && { 'data-customer-data': paymentData.userData })}
            ></script>
        </div>
    );
};

export default BoldButton;
