import React from "react";
import {Container} from "reactstrap";

//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";
import ReviewsSlider from "../../components/Shared/ReviewsSlider";
import {reviews} from "../../common/data";

const Testimonials = () => {
    return (
        <React.Fragment>
            <Container className="mt-100 mt-60">
                {/* render Section title */}
                <SectionTitle
                    title="¿Qué dicen nuestros estudiantes?"
                    desc="Conoce cómo Formación 4.0 ha transformado las carreras de nuestros estudiantes, ayudándoles a alcanzar sus metas profesionales."
                />

                {/* clients slider */}
                <ReviewsSlider  reviews={reviews} colClass="mt-4"/>
            </Container>
        </React.Fragment>
    );
};

export default Testimonials;
