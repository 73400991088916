import {formacion40PixelId} from "../../../common/data/landing";

export const sendMetaPixelEvent = (eventName, customData, pixelId) => {
    pixelId = pixelId || formacion40PixelId;
    if (typeof window.fbq == "undefined") {
        console.warn("Meta Pixel no está inicializado.");
        initializePixel(pixelId);
    }
    customData = !customData ? {...getMetaCookies()} : customData;
    console.log("Enviando evento Meta Pixel:", eventName, customData, pixelId);
    window.fbq("track", eventName, customData);
    console.log(`Meta Pixel Event enviado: ${eventName}`, customData);
};

export const initializePixel = (pixelId) => {
    pixelId = pixelId || formacion40PixelId;
    sessionStorage.setItem('initializedPixelId', pixelId);
    (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    window.fbq('init', pixelId);
    console.log("Meta Pixel inicializado.");
    const fbclid = sessionStorage.getItem('fbclid');
    if (fbclid) {
        const timestamp = Date.now();
        // Crear _fbc si no existe
        const fbcCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('_fbc='));
        if (!fbcCookie) {
            const fbcValue = `fb.1.${timestamp}.${fbclid}`;
            document.cookie = `_fbc=${fbcValue}; path=/; SameSite=Lax; Secure`;
            sessionStorage.setItem('fbc', fbcValue);
        }
        // Crear _fbp si no existe
        const fbpCookie = document.cookie.split('; ').find(cookie => cookie.startsWith('_fbp='));
        if (!fbpCookie) {
            const randomNumber = Math.floor(Math.random() * 1e21);
            const fbpValue = `fb.1.${timestamp}.${randomNumber}`;
            document.cookie = `_fbp=${fbpValue}; path=/; SameSite=Lax; Secure`;
            sessionStorage.setItem('fbp', fbpValue);
        }
    }
}
export const getMetaCookies = () => {
    console.log('getMetaCookies');
    // Obtener el `fbc` de las cookies
    const fbc = document.cookie
        .split("; ")
        .find((row) => row.startsWith("_fbc"))
        ?.split("=")[1];
    // Obtener el `fbp` de las cookies (si existe)
    const fbp = document.cookie
        .split("; ")
        .find((row) => row.startsWith("_fbp"))
        ?.split("=")[1];
    const fbclid = document.cookie
        .split("; ")
        .find((row) => row.startsWith("fbclid"))
        ?.split("=")[1];
    // Obtener el agente del navegador
    const clientUserAgent = navigator.userAgent;
    const userData = {
        client_user_agent: clientUserAgent,
        pixel_id: sessionStorage.getItem('initializedPixelId')
    };

    if (fbc) {
        userData.fbc = fbc;
    }

    if (fbp) {
        userData.fbp = fbp;
    }

    if (fbclid) {
        userData.fbclid = fbclid;
    }
    console.log('getMetaCookies', userData);
    return userData;
};