import {getMetaCookies, sendMetaPixelEvent} from "./MetaApiService";

export const sendContactInfo = (values) => {
    const data = {
        name: values.name,
        email: values.email,
        description: values.message,
        phone: values.phone,
        metadata: getMetaCookies(),
    };


    return fetch('https://vm-gcp.n8n.innovatexiot.com/webhook/4beb5e2e-8dea-4737-8874-9f9abb3ba1d2/form-contact/' + values.identifier, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(response => response.json())
        .then(data => {
            sendMetaPixelEvent('Contact', values);
            return data;
        });
}