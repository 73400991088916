// React Basic and Bootstrap
import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Alert, Card, CardBody, Col, Container, Form, Input, Label, Row,} from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

// import images
import contact from "../../../assets/images/contact.svg";
import {coinnovaWhatsappNumber} from "../../../common/data/landing";

class PageContactOne extends Component {
    constructor(props) {

        super(props);
        this.state = {

            Contactvisible: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.sendMail.bind(this);
        this.callNumber.bind(this);


    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({Contactvisible: true});
    }

    componentDidMount() {
        document.body.classList = "";
        document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
        window.addEventListener("scroll", this.scrollNavigation, true);
        // document.querySelector(".cookie-popup")?.classList.remove("show");
    }


    // Make sure to remove the DOM listener when the component is unmounted.
    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var doc = document.documentElement;
        const navBar = document.getElementById("topnav");
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (navBar != null) {
            if (top > 80) {
                navBar.classList.add("nav-sticky");
            } else {
                navBar.classList.remove("nav-sticky");
                document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
                document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
                document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
            }
        }
    };

    sendMail() {
        window.location.href = "mailto:contact@example.com";
    }

    callNumber() {
        const phoneNumber = coinnovaWhatsappNumber;
        const message = "Hola, he visitado el sitio web de Formación 4.0 y me interesa conocer más sobre los programas y oportunidades que ofrecen. ¿Podrían compartirme más detalles? ¡Gracias!";
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.location.href = whatsappUrl;
    }

    render() {
        return (
            <React.Fragment>
                {/* breadcrumb */}
                <section className="bg-half-170 bg-light d-table w-100">
                    <Container>
                        <Row className="mt-5 justify-content-center">
                            <Col lg={12} className="text-center">
                                <div className="pages-heading">
                                    <h4 className="title mb-0"> Contáctanos </h4>
                                </div>
                            </Col>
                        </Row>

                        <div className="position-breadcrumb">
                            <nav aria-label="breadcrumb" className="d-inline-block">
                                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                                    <li className="breadcrumb-item"><Link to="/">InnovateX</Link></li>
                                    {" "}
                                    <li className="breadcrumb-item"><Link to="#">Página</Link></li>
                                    {" "}
                                    <li className="breadcrumb-item"><Link to="#">Contacto</Link></li>
                                </ul>
                            </nav>
                        </div>
                    </Container>
                </section>
                <div className="form-icon position-relative">
                    <div className="shape overflow-hidden text-white">
                        <svg
                            viewBox="0 0 2880 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                </div>

                <section className="section pb-0">
                    <Container>
                        <Row>
                            <Col md={6}>
                                <Card className="border-0 text-center features feature-primary feature-clean">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-phone d-block rounded h3 mb-0"></i>
                                    </div>
                                    <div className="content mt-6">
                                        <h5 className="fw-bold">Teléfono</h5>
                                        <p className="text-muted">
                                            Comienza a trabajar con InnovateX que puede proporcionar todo
                                        </p>
                                        <Link
                                            to="#"
                                            onClick={this.callNumber}
                                            className="text-primary"
                                        >
                                            +573042226433
                                        </Link>
                                    </div>
                                </Card>
                            </Col>

                            <Col md={6} className="mt-6 mt-sm-0 pt-2 pt-sm-0">
                                <Card className="border-0 text-center features feature-primary feature-clean">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-envelope d-block rounded h3 mb-0"></i>
                                    </div>
                                    <div className="content mt-6">
                                        <h5 className="fw-bold">Correo Electrónico</h5>
                                        <p className="text-muted">
                                            Comienza a trabajar con InnovateX que puede proporcionar todo
                                        </p>
                                        <Link
                                            to="#"
                                            onClick={this.sendMail}
                                            className="text-primary"
                                        >
                                            info@innovatexiot.com
                                        </Link>
                                    </div>
                                </Card>
                            </Col>

                        </Row>
                    </Container>

                    <Container className="mt-100 mt-60">
                        <Row className="align-items-center">
                            <Col
                                lg={5}
                                md={6}
                                className="mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1"
                            >
                                <Card className="shadow rounded border-0">
                                    <CardBody className="py-5">
                                        <h4 className="card-title">¡Ponte en Contacto!</h4>
                                        <div className="custom-form mt-3">
                                            <div id="message"></div>
                                            <Alert
                                                color="primary"
                                                isOpen={this.state.Contactvisible}
                                                toggle={() => {
                                                    this.setState({
                                                        Contactvisible: !this.state.Contactvisible,
                                                    });
                                                }}
                                            >
                                                Detalles de contacto enviados con éxito.
                                            </Alert>
                                            <Form
                                                method="post"
                                                onSubmit={this.handleSubmit}
                                                name="contact-form"
                                                id="contact-form"
                                            >
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label">
                                                                Tu Nombre <span className="text-danger">*</span>
                                                            </Label>
                                                            <div className="form-icon position-relative">
                                                                <i>
                                                                    <FeatherIcon
                                                                        icon="user"
                                                                        className="fea icon-sm icons"
                                                                    />
                                                                </i>
                                                            </div>
                                                            <Input
                                                                name="name"
                                                                id="name"
                                                                type="text"
                                                                className="form-control ps-5"
                                                                placeholder="Nombre :"
                                                                required
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="form-label">
                                                                Tu Correo Electrónico{" "}
                                                                <span className="text-danger">*</span>
                                                            </Label>
                                                            <div className="form-icon position-relative">
                                                                <i>
                                                                    <FeatherIcon
                                                                        icon="mail"
                                                                        className="fea icon-sm icons"
                                                                    />
                                                                </i>
                                                            </div>
                                                            <Input
                                                                name="email"
                                                                id="email"
                                                                type="email"
                                                                className="form-control ps-5"
                                                                placeholder="Tu correo electrónico :"
                                                                required
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <Label className="form-label">Asunto</Label>
                                                            <div className="form-icon position-relative">
                                                                <i>
                                                                    <FeatherIcon
                                                                        icon="book"
                                                                        className="fea icon-sm icons"
                                                                    />
                                                                </i>
                                                            </div>
                                                            <Input
                                                                name="subject"
                                                                id="subject"
                                                                className="form-control ps-5"
                                                                placeholder="Tu asunto :"
                                                                required
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <Label className="form-label">Comentarios</Label>
                                                            <div className="form-icon position-relative">
                                                                <i>
                                                                    <FeatherIcon
                                                                        icon="message-circle"
                                                                        className="fea icon-sm icons"
                                                                    />
                                                                </i>
                                                            </div>
                                                            <textarea
                                                                name="comments"
                                                                id="comments"
                                                                rows="4"
                                                                className="form-control ps-5"
                                                                placeholder="Tu mensaje :"
                                                            ></textarea>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} className="text-center">
                                                        <div className="d-grid">
                                                            <input
                                                                type="submit"
                                                                id="submit"
                                                                name="send"
                                                                className="submitBnt btn btn-primary"
                                                                value="Enviar Mensaje"
                                                            />
                                                        </div>
                                                        <div id="simple-msg"></div>

                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={7} md={{size: 6, order: 2}} xs={{order: 1}}>
                                <Card className="border-0">
                                    <CardBody className="p-0">
                                        <img src={contact} className="img-fluid" alt="InnovateX"/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                    <Container className="mt-100 mt-60" fluid>
                        <Row>
                            <Col xs={12} className="p-0">
                                <Card className="map border-0">
                                    <CardBody className="p-0">
                                        <iframe
                                            title="myFrame"
                                            src="//www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                                            style={{border: "0"}}
                                            className="rounded"
                                            allowFullScreen
                                        ></iframe>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default PageContactOne;
