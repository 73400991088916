import React, {Component} from 'react';
import Section from './Section';
import Team from './Team';
import Popup from '../../components/Layout/popup';

//import images
import client1 from "../../assets/images/client/amazon.svg";
import client2 from "../../assets/images/client/google.svg";
import client3 from "../../assets/images/client/lenovo.svg";
import client4 from "../../assets/images/client/paypal.svg";
import client5 from "../../assets/images/client/shopify.svg";
import client6 from "../../assets/images/client/spotify.svg";
import Cta from './Cta';
import Process from "./Process";
import {getMetaCookies, sendMetaPixelEvent} from "../../components/Shared/services/MetaApiService";
import {baseGatewayUrl, coinnovaPixelId} from "../../common/data/landing";
import Pricing from "./Pricing";

export default class index extends Component {

    base_url = baseGatewayUrl + '/nova-api/payments/{ORDER_ID}/status';

    constructor(props) {
        super(props);
        this.state = {
            partners: [
                {image: client1},
                {image: client2},
                {image: client3},
                {image: client4},
                {image: client5},
                {image: client6},
            ],
        };
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const fbclid = urlParams.get('fbclid');
        if (fbclid) {
            document.cookie = `fbclid=${fbclid}; path=/; max-age=31536000`; // Guardar por 1 año
        }
        const boldOrderId = urlParams.get('bold-order-id');
        const boldTxStatus = urlParams.get('bold-tx-status');
        if (boldOrderId && boldTxStatus) {
            this.updateOrderStatus(boldOrderId, boldTxStatus);
        }

        sendMetaPixelEvent('PageView', this.getUserDataFromBrowser(), coinnovaPixelId);
        document.body.classList = "";
        document.querySelectorAll("#buyButton").forEach((navLink) => {
            navLink.classList.add("btn-light")
            navLink.classList.remove("btn-soft-primary");
            document.getElementById("top-menu")?.classList.add("nav-light");
        });
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    updateOrderStatus(boldOrderId, boldTxStatus) {
        const finalUrl = this.base_url.replace('{ORDER_ID}', boldOrderId);
        if (boldOrderId && boldTxStatus) {
            fetch(finalUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({status: boldTxStatus}),
            })
                .then(response => response.json())
                .then(data => console.log('Service Success:', data))
                .catch(error => console.error('Service Error:', error));
        }
    }


    getUserDataFromBrowser() {
        return getMetaCookies();
    };

    // Make sure to remove the DOM listener when the component is unmounted.
    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top > 80) {
            document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
            document.querySelector(".settingbtn")?.classList.remove("btn-light");
            document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
            document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");

            document.getElementById("topnav")?.classList.add("nav-sticky");
        } else {
            document.querySelector(".shoppingbtn")?.classList.remove("btn-primary");
            document.querySelector(".settingbtn")?.classList.remove("btn-soft-primary");
            document.querySelector(".shoppingbtn")?.classList.add("btn-light");
            document.querySelector(".settingbtn")?.classList.add("btn-light");
            document.getElementById("topnav")?.classList.remove("nav-sticky");
        }
    };

    render() {
        return (
            <React.Fragment>
                <Section/>
                <section className="section" id="how-its-work-section">
                    <Process/>
                    <Team/>
                </section>
                <section className="py-4 border-bottom border-top">
                </section>
                <Cta/>
                <Pricing/>
                <Popup/>
            </React.Fragment>
        );
    }
}
