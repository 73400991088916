import React from 'react'
import {Navigate} from "react-router-dom";

//Main Index
import Main from "../pages/Home/indexMain";
import PageTerms from "../pages/Utility/PageTerms";
import PagePrivacy from "../pages/Utility/PagePrivacy";
import PageContactOne from "../pages/Pages/Contact/PageContactOne";
import OnlineLearning from "../pages/OnlineLearning";
import Blockchain from '../pages/Blockchain/index';


const routes = [


    //Index Main
    {
        path: "/", exact: true, component: <Navigate to="/index"/>,
    },
    {path: "/index", component: <Main/>, isTopbarDark: true},
    {path: "/page-terms", component: <PageTerms/>, isTopbarDark: true},
    {path: "/page-privacy", component: <PagePrivacy/>, isTopbarDark: true},
    {path: "/page-contact-one", component: <PageContactOne/>, isTopbarDark: true},
    {path: "/formation-4-0", component: <OnlineLearning/>, isTopbarDark: true},
    {path: "/coinnova", component: <Blockchain/>},


];

export default routes;
