import React, {Component} from 'react'

//import images
import crypto1 from '../../assets/images/crypto/novaRoadmap.png';
import crypto2 from '../../assets/images/crypto/iphone.png';
import {Col, Container, Row} from 'reactstrap';
import {coinnovaPixelId, coinnovaWhatsappNumber} from "../../common/data/landing";
import {sendMetaPixelEvent} from "../../components/Shared/services/MetaApiService";

export default class Team extends Component {

    sendWhatsappMessage() {
        sendMetaPixelEvent(
            'Contact', {
                contact_method: 'WhatsApp',
                page: window.location.href
            },
            coinnovaPixelId)
        const phoneNumber = coinnovaWhatsappNumber;
        const message = "Hola, he explorado el sitio web de COINNOVA y estoy muy interesado en conocer más detalles sobre el proyecto y sus oportunidades. ¿Podrían brindarme más información? ¡Gracias!";
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.location.href = whatsappUrl;
    }

    render() {
        return (
            <React.Fragment>
                <Container className="mt-100 mt-60">
                    <Row className="align-items-center">
                        <Col lg={6} md={6}>
                            <img src={crypto1} className="img-fluid" alt=""/>
                        </Col>

                        <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title ms-lg-5">
                                <h4 className="title mb-4">Un Modelo Transparente y Sostenible</h4>
                                <p className="text-muted">El diseño de COINNOVA asegura que cada token impulse el
                                    crecimiento del ecosistema Innovatex, con una distribución equilibrada que refuerza
                                    la sostenibilidad, el desarrollo y la comunidad</p>
                                <ul className="list-unstyled text-muted">
                                    <li className="mb-1">
                                        <strong>Democratización:</strong> Acceso inclusivo a
                                        tecnología para talento emergente.
                                    </li>
                                    <li className="mb-1">
                                        <strong>Economía Circular:</strong> Formación, desarrollo y sostenibilidad
                                        tecnológica.
                                    </li>
                                    <li className="mb-1">
                                        <strong>Utilidad Real:</strong> Token para formación y participación en
                                        proyectos innovadores.
                                    </li>
                                </ul>
                                <button onClick={this.sendWhatsappMessage} className="btn btn-primary">
                                    Conoce Más
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className="mt-100 mt-60">
                    <Row className="align-items-center">
                        <Col lg={7} md={6} className="order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="section-title me-lg-5">
                                <h4 className="title mb-4">Participa en productos con potencial de ser grandes
                                    startups</h4>
                                <p className="text-muted">Forma parte de la construcción de ideas innovadoras que pueden
                                    convertirse en el próximo gran cambio tecnológico</p>
                                <ul className="list-unstyled text-muted">
                                    <li className="mb-1">
                                        <strong>Acceso exclusivo: </strong>Sé parte de proyectos innovadores en sus
                                        primeras etapas de desarrollo.
                                    </li>
                                    <li className="mb-1">
                                        <strong>Crecimiento conjunto: </strong> Contribuye al éxito de ideas que pueden
                                        transformar
                                        industrias.
                                    </li>
                                    <li className="mb-1">
                                        <strong>Impacto global: </strong> Ayuda a impulsar soluciones tecnológicas hacia
                                        el mercado
                                        global.
                                    </li>
                                </ul>
                                <button onClick={this.sendWhatsappMessage} className="btn btn-primary">
                                    Conoce Más
                                </button>
                            </div>
                        </Col>

                        <Col lg={5} md={6} className="order-1 order-md-2">
                            <img src={crypto2} className="img-fluid" alt=""/>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}
