// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, CardBody, Card } from "reactstrap";
import go_image from "../../assets/images/icon-512.png";


//Import Icons
import FeatherIcon from "feather-icons-react";

class PagePrivacy extends Component {
  render() {
    return (
        <React.Fragment>
          {/* breadcrumb */}
          <section className="bg-half-170 bg-light d-table w-100 d-print-none">
            <Container>
              <Row className="mt-5 justify-content-center">
                <Col lg={12} className="text-center">
                  <div className="pages-heading">
                    <h1 className="title">Política de Privacidad</h1>
                    <ul className="list-unstyled mt-4 mb-0">
                      <li className="list-inline-item h6 date text-muted">
                        <span className="text-dark">Última Revisión:</span> 16 de Diciembre, 2024
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>

              <div className="position-breadcrumb">
                <nav aria-label="breadcrumb" className="d-inline-block">
                  <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li className="breadcrumb-item">
                      <Link to="/">Go</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Política de Privacidad
                    </li>
                  </ul>
                </nav>
                <div className="mt-3">
                  <img
                      src={go_image} // Ruta de tu imagen
                      alt="Política de Privacidad"
                      className="img-fluid"
                      style={{maxWidth: "200px"}} // Ajusta el tamaño según sea necesario
                  />
                </div>
              </div>
            </Container>
          </section>

          <section className="section">
            <Container>
              <Row className="justify-content-center">
                <Col lg={9}>
                  <Card className="card shadow rounded border-0">
                    <CardBody>
                      <h2 className="card-title">1. Información del Desarrollador</h2>
                      <p className="text-muted">
                        Esta política pertenece a <strong>Innovatex IoT</strong>. Si tienes alguna consulta sobre privacidad, puedes contactarnos en:{" "}
                        <a href="mailto:info@innovatexiot.com">info@innovatexiot.com</a>
                      </p>

                      <h2 className="card-title">2. Información que Recopilamos</h2>
                      <ul className="text-muted">
                        <li>Datos personales: Nombre, correo electrónico, número de teléfono.</li>
                        <li>Datos de ubicación: Para funcionalidades de entrega y optimización del servicio.</li>
                        <li>Información de uso: Datos de comportamiento en la aplicación.</li>
                      </ul>

                      <h2 className="card-title">3. Propósito de Uso de Datos</h2>
                      <p className="text-muted">
                        Utilizamos tu información para los siguientes fines:
                      </p>
                      <ul className="feature-list text-muted">
                        <li>
                          <FeatherIcon icon="arrow-right" className="fea icon-sm me-2" />
                          Proporcionar y mejorar nuestros servicios.
                        </li>
                        <li>
                          <FeatherIcon icon="arrow-right" className="fea icon-sm me-2" />
                          Comunicarnos contigo respecto a tu cuenta o actualizaciones.
                        </li>
                        <li>
                          <FeatherIcon icon="arrow-right" className="fea icon-sm me-2" />
                          Garantizar la seguridad y funcionalidad de la app.
                        </li>
                      </ul>

                      <h2 className="card-title">4. Seguridad de los Datos</h2>
                      <p className="text-muted">
                        Protegemos tu información implementando medidas de seguridad técnicas y organizativas para evitar accesos no autorizados.
                      </p>

                      <h2 className="card-title">5. Conservación y Eliminación de Datos</h2>
                      <p className="text-muted">
                        Tus datos serán conservados solo el tiempo necesario para cumplir con el propósito del servicio. Puedes solicitar la eliminación de tu información enviando un correo a{" "}
                        <a href="mailto:info@innovatexiot.com">info@innovatexiot.com</a>.
                      </p>

                      <h2 className="card-title">6. Compartición de Datos</h2>
                      <p className="text-muted">
                        No compartimos tus datos con terceros, excepto cuando sea estrictamente necesario para cumplir con la funcionalidad de la aplicación o requerido por la ley.
                      </p>

                      <h2 className="card-title">7. Derechos del Usuario</h2>
                      <p className="text-muted">
                        Tienes derecho a acceder, corregir o eliminar tus datos. Si necesitas ejercer estos derechos, contáctanos a{" "}
                        <a href="mailto:info@innovatexiot.com">info@innovatexiot.com</a>.
                      </p>

                      <Link to="/" className="btn btn-soft-primary mt-3 d-print-none">
                        Regresar al Inicio
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </React.Fragment>
    );
  }
}

export default PagePrivacy;
