import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import {getMetaCookies, sendMetaPixelEvent} from "../../components/Shared/services/MetaApiService";
import BoldButton from "../Utility/BoldButton";
import {baseGatewayUrl} from "../../common/data/landing";

const Pricing = ({plans}) => {
    const [paymentData, setPaymentData] = useState(null);
    const [boldData, setBoldData] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: ''
    });

    useEffect(() => {
    }, []);

    const handlePaymentClick = (item) => {
        console.log("handlePaymentClick", item);
        const customData = getPaymentDataFromItem(item);
        localStorage.setItem(item.reference, JSON.stringify(customData));
        sendMetaPixelEvent('InitiateCheckout', customData);
        setPaymentData(customData);
        setModalOpen(true);
    };

    const getPaymentDataFromItem = (item) => {
        const userData = {
            ...getUserDataFromBrowser(),
        };
        return {
            currency: item.currency,
            amount: item.amount,
            content_name: item.name,
            content_ids: [item.reference],
            content_type: 'product',
            reference: item.reference,
            signature: item.signature,
            description: item.description,
            userData
        };

    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = () => {
        let userData = {
            email: formData.email,
            fullName: formData.name,
            phone: formData.phone
        };
        paymentData.userData = JSON.stringify({
            ...userData
        })

        setBoldData(paymentData);
        setModalOpen(false);
        const pixelData = {
            currency: paymentData.currency,
            content_ids: [paymentData.reference],
            value: paymentData.amount,
            ...paymentData,
            ...userData
        }
        sendMetaPixelEvent('AddPaymentInfo', pixelData);
        updatePaymentData(pixelData).then();
    };


    const getUserDataFromBrowser = () => {
        return getMetaCookies();
    };
    const isFormValid = () => {
        return formData.name && formData.email && formData.phone;
    };


    async function updatePaymentData(item) {
        const base_url = baseGatewayUrl + '/nova-api/payments';
        await fetch(`${base_url}/sales`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(item),
        });
    }

    function getPaymentData(item) {
        let data = getPaymentDataFromItem(item);
        data.userData = paymentData.userData

        return data;
    }

    return (
        <Container>
            <Row className="align-items-center">
                {plans.map((item, key) => (
                    <Col key={key} md={4} className="col-12 mt-4 pt-2">
                        <Card
                            className={
                                item.isActive
                                    ? "card pricing pricing-primary starter-plan bg-light py-5 border-0 rounded text-center"
                                    : "card pricing pricing-primary bg-light py-5 border-0 rounded text-center"
                            }
                        >
                            <CardBody>
                                <h6 className="title name fw-bold text-uppercase mb-4">{item.name}</h6>
                                <div className="d-flex justify-content-center mb-4">
                  <span className="price h1 mb-0">
                    {item.amount.toLocaleString("es-CO", {
                        style: "currency",
                        currency: "COP",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    })}
                  </span>
                                </div>

                                <ul className="list-unstyled mb-0 ps-0">
                                    <li key={key} className="h6 text-muted mb-0">
                    <span className="icon h5 me-2">
                      <i className="uil uil-check-circle align-middle"></i>
                    </span>
                                        {item.description}
                                    </li>
                                </ul>

                                {!boldData ? (
                                    <Button
                                        color="primary"
                                        onClick={() => handlePaymentClick(item)}
                                    >
                                        Solicitar Pago
                                    </Button>
                                ) : (
                                    <BoldButton paymentData={getPaymentData(item)}/>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                ))}
                <br/>
                <p className="text-muted mt-3" style={{fontSize: "0.9rem"}}>
                    **El pago es procesado a través de la pasarela Bold. Tu información sensible, como
                    números de tarjetas
                    o contraseñas, no es gestionada por nuestro sitio.
                </p>
            </Row>

            <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
                <ModalHeader toggle={() => setModalOpen(!modalOpen)}>Informacion para el pago</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="name">Nombre</Label>
                            <Input type="text" name="name" id="name" value={formData.name}
                                   onChange={handleInputChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">Correo</Label>
                            <Input type="email" name="email" id="email" value={formData.email}
                                   onChange={handleInputChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="phone">Teléfono</Label>
                            <Input type="text" name="phone" id="phone" value={formData.phone}
                                   onChange={handleInputChange}/>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => handleSubmit()}
                            disabled={!isFormValid()}>Enviar</Button>{' '}
                    <Button color="secondary" onClick={() => setModalOpen(false)}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
};

export default Pricing;
