import React from 'react';
import {UncontrolledAlert} from "reactstrap";
import {Link} from 'react-router-dom';

const Popup = () => {
    return (
        <React.Fragment>
            <UncontrolledAlert className="card cookie-popup shadow rounded py-3 px-4">
                <p className="text-muted mb-0 fs-6">Este sitio web utiliza cookies para brindarte una excelente
                    experiencia de usuario. Al usarlo,
                    aceptas nuestro <Link to="https://themesbrand.com" target="_blank" rel="noopener noreferrer"
                                          className="text-success h6">uso de cookies</Link></p>
                <div className="cookie-popup-actions text-end">
                    {/* <button>
                        <i className="uil uil-times text-dark fs-4"></i>
                    </button> */}
                </div>
            </UncontrolledAlert>

        </React.Fragment>
    );
}

export default Popup;