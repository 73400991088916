import React, {useState} from "react";
import {Button, Card, CardBody, Col, Container, Input, Row} from "reactstrap";
import {baseGatewayUrl} from "../../common/data/landing";
import {getMetaCookies, sendMetaPixelEvent} from "../../components/Shared/services/MetaApiService";
import BoldButton from "../Utility/BoldButton";

const Pricing = () => {
    const baseUrl = baseGatewayUrl + '/nova-api/payments/pre-sale';
    const [novaCount, setNovaCount] = useState(1); // Número de Novas a comprar, inicializa en 1
    const [totalAmount, setTotalAmount] = useState(1); // Total en USD
    const [walletAddress, setWalletAddress] = useState(""); // Dirección de billetera de Solana
    const [paymentData, setPaymentData] = useState(null); // Datos del servicio para el botón de Bold

    const handlePaymentRequest = async () => {
        console.log("Procesando compra de Novas:", novaCount, "Wallet:", walletAddress);

        if (!walletAddress) {
            alert("Por favor, ingrese una dirección de billetera válida.");
            return;
        }

        const userData = {
            ...getUserDataFromBrowser(),
        };

        const customData = {
            currency: "USD",
            value: totalAmount, // Total a pagar en USD
            content_name: "Compra de Novas",
            content_ids: ["nova_purchase"],
            content_type: "product",
            quantity: novaCount,
            walletAddress,
            userData,
        };

        localStorage.setItem("nova_purchase", JSON.stringify(customData));
        sendMetaPixelEvent("InitiateCheckout", customData);

        try {
            const response = await fetch(baseUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({amount: totalAmount, walletAddress}),
            });

            if (!response.ok) {
                throw new Error("Error al procesar el pago");
            }

            const paymentData = await response.json();
            setPaymentData(paymentData); // Guardar datos del servicio para renderizar el botón de Bold
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const getUserDataFromBrowser = () => {
        return getMetaCookies();
    };

    const handleNovaChange = (e) => {
        const value = e.target.value;
        setNovaCount(value);
        setTotalAmount(value > 0 ? value : 0); // Calcula el total si el valor es válido
    };

    return (
        <Container>
            <Row className="align-items-center justify-content-center">
                <Col md={6} className="col-12 mt-4 pt-2">
                    <Card className="card pricing pricing-primary bg-light py-5 border-0 rounded text-center">
                        <CardBody>
                            <h6 className="title name fw-bold text-uppercase mb-4">Compra Novas</h6>
                            <div className="d-flex justify-content-center mb-4">
                                <Input
                                    type="number"
                                    min="1"
                                    value={novaCount}
                                    onChange={handleNovaChange}
                                    className="form-control w-50 text-center"
                                />
                            </div>
                            <div className="d-flex justify-content-center mb-4">
                                <Input
                                    type="text"
                                    placeholder="Ingresa tu billetera de Solana"
                                    value={walletAddress}
                                    onChange={(e) => setWalletAddress(e.target.value)}
                                    className="form-control w-75 text-center"
                                />
                            </div>
                            <div className="d-flex justify-content-center mb-4">
                                <span className="price h1 mb-0">{totalAmount.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 0,
                                })}</span>
                            </div>

                            {!paymentData ? (
                                <Button
                                    color="primary"
                                    onClick={handlePaymentRequest}
                                    disabled={totalAmount <= 0 || !walletAddress}
                                >
                                    Solicitar Pago
                                </Button>
                            ) : (
                                <BoldButton paymentData={paymentData}/>
                            )}
                            <div>
                                <br/>
                                <p className="text-muted mt-3" style={{fontSize: "0.9rem"}}>
                                    El pago es procesado a través de la pasarela Bold. Tu información sensible, como
                                    números de tarjetas
                                    o contraseñas, no es gestionada por nuestro sitio.
                                </p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Pricing;
