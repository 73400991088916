// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse,
} from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

class PageTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      col1: true,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
    };
    this.t_col1 = this.t_col1.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.t_col3 = this.t_col3.bind(this);
    this.t_col4 = this.t_col4.bind(this);
    this.t_col5 = this.t_col5.bind(this);
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
    });
  }
  t_col2() {
    this.setState({
      col2: !this.state.col2,
      col1: false,
      col3: false,
      col4: false,
      col5: false,
    });
  }
  t_col3() {
    this.setState({
      col3: !this.state.col3,
      col2: false,
      col1: false,
      col4: false,
      col5: false,
    });
  }
  t_col4() {
    this.setState({
      col4: !this.state.col4,
      col2: false,
      col3: false,
      col1: false,
      col5: false,
    });
  }
  t_col5() {
    this.setState({
      col5: !this.state.col5,
      col2: false,
      col3: false,
      col1: false,
      col4: false,
    });
  }

  componentDidMount() {
    document.body.classList = "";
    document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
        document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
        document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
      }
    }
  };

  render() {
    return (
        <React.Fragment>
          {/* breadcrumb */}
          <section className="bg-half-170 bg-light d-table w-100">
            <Container>
              <Row className="mt-5 justify-content-center">
                <Col lg={12} className="text-center">
                  <div className="pages-heading">
                    <h4 className="title mb-0"> Términos de Servicio </h4>
                  </div>
                </Col>
              </Row>

              <div className="position-breadcrumb">
                <nav aria-label="breadcrumb" className="d-inline-block">
                  <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                    <li className="breadcrumb-item"><Link to="/">Go</Link></li>{" "}
                    <li className="breadcrumb-item"><Link to="#">Página</Link></li>{" "}
                    <li className="breadcrumb-item"><Link to="#">Utilidad</Link></li>{" "}
                    <li className="breadcrumb-item active" aria-current="page">Términos</li>
                  </ul>
                </nav>
              </div>
            </Container>
          </section>
          <div className="position-relative">
            <div className="shape overflow-hidden text-white">
              <svg
                  viewBox="0 0 2880 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                    fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>

          <section className="section">
            <Container>
              <Row className="justify-content-center">
                <Col lg={9}>
                  <Card className="shadow border-0 rounded">
                    <CardBody>
                      <h5 className="card-title">Introducción :</h5>
                      <p className="text-muted">
                        Bienvenido a Go, tu plataforma de movilidad y logística. Estos términos de servicio regulan el uso de nuestra aplicación y servicios.
                      </p>

                      <h5 className="card-title">Acuerdos de Usuario :</h5>
                      <p className="text-muted">
                        Al utilizar Go, aceptas cumplir con estos términos. Nos reservamos el derecho de modificar estos términos en cualquier momento.
                      </p>
                      <p className="text-muted">
                        Go proporciona servicios de transporte y entrega a través de una red de conductores y repartidores independientes. Al utilizar nuestros servicios, aceptas que Go no es responsable de las acciones de estos terceros.
                      </p>

                      <h5 className="card-title">Restricciones :</h5>
                      <p className="text-muted">
                        Estás específicamente restringido de lo siguiente:
                      </p>
                      <ul className="list-unstyled feature-list text-muted">
                        <li>
                          <i>
                            <FeatherIcon
                                icon="arrow-right"
                                className="fea icon-sm me-2"
                            />
                          </i>
                          Usar nuestros servicios para actividades ilegales
                        </li>
                        <li>
                          <i>
                            <FeatherIcon
                                icon="arrow-right"
                                className="fea icon-sm me-2"
                            />
                          </i>
                          Comprometer la seguridad de nuestros conductores y repartidores
                        </li>
                        <li>
                          <i>
                            <FeatherIcon
                                icon="arrow-right"
                                className="fea icon-sm me-2"
                            />
                          </i>
                          Manipular o interferir con la operación de la aplicación
                        </li>
                      </ul>

                      <h5 className="card-title">Preguntas y Respuestas de los Usuarios :</h5>
                      <div className="faq-content mt-4">
                        <div className="accordion" id="accordionExample">
                          <Card className="border-0 rounded mb-2">
                            <Link
                                to="#"
                                onClick={this.t_col1}
                                className={
                                  this.state.col1
                                      ? "faq position-relative text-primary"
                                      : "faq position-relative text-dark"
                                }
                            >
                              <CardHeader
                                  className="border-0 bg-light p-3"
                                  id="headingOne"
                              >
                                <h6 className="title mb-0">
                                  ¿Cómo funciona Go?
                                  <i
                                      className={
                                        this.state.col1
                                            ? "mdi mdi-chevron-up float-end"
                                            : "mdi mdi-chevron-down float-end"
                                      }
                                  ></i>
                                </h6>
                              </CardHeader>
                            </Link>
                            <Collapse isOpen={this.state.col1}>
                              <CardBody>
                                <p className="text-muted mb-0 faq-ans">
                                  Go conecta a usuarios con conductores y repartidores para ofrecer servicios de transporte y entrega de manera eficiente.
                                </p>
                              </CardBody>
                            </Collapse>
                          </Card>

                          <Card className="border-0 rounded mb-2">
                            <Link
                                to="#"
                                onClick={this.t_col2}
                                className={
                                  this.state.col2
                                      ? "faq position-relative text-primary"
                                      : "faq position-relative text-dark"
                                }
                            >
                              <CardHeader
                                  className="border-0 bg-light p-3"
                                  id="headingTwo"
                              >
                                <h6 className="title mb-0">
                                  ¿Necesito un diseñador para usar Go?
                                  <i
                                      className={
                                        this.state.col2
                                            ? "mdi mdi-chevron-up float-end"
                                            : "mdi mdi-chevron-down float-end"
                                      }
                                  ></i>
                                </h6>
                              </CardHeader>
                            </Link>
                            <Collapse isOpen={this.state.col2}>
                              <CardBody>
                                <p className="text-muted mb-0 faq-ans">
                                  No, Go está diseñado para ser fácil de usar sin necesidad de conocimientos técnicos avanzados.
                                </p>
                              </CardBody>
                            </Collapse>
                          </Card>

                          <Card className="border-0 rounded mb-2">
                            <Link
                                to="#"
                                onClick={this.t_col3}
                                className={
                                  this.state.col3
                                      ? "faq position-relative text-primary"
                                      : "faq position-relative text-dark"
                                }
                            >
                              <CardHeader
                                  className="border-0 bg-light p-3"
                                  id="headingfive"
                              >
                                <h6 className="title mb-0">
                                  ¿Qué necesito hacer para empezar a usar Go?
                                  <i
                                      className={
                                        this.state.col3
                                            ? "mdi mdi-chevron-up float-end"
                                            : "mdi mdi-chevron-down float-end"
                                      }
                                  ></i>
                                </h6>
                              </CardHeader>
                            </Link>
                            <Collapse isOpen={this.state.col3}>
                              <CardBody>
                                <p className="text-muted mb-0 faq-ans">
                                  Simplemente descarga la aplicación, crea una cuenta y comienza a solicitar servicios de transporte o entrega.
                                </p>
                              </CardBody>
                            </Collapse>
                          </Card>

                          <Card className="border-0 rounded mb-2">
                            <Link
                                to="#"
                                onClick={this.t_col4}
                                className={
                                  this.state.col4
                                      ? "faq position-relative text-primary"
                                      : "faq position-relative text-dark"
                                }
                            >
                              <CardHeader
                                  className="border-0 bg-light p-3"
                                  id="headingfive"
                              >
                                <h6 className="title mb-0">
                                  ¿Qué pasa cuando hago un pedido?
                                  <i
                                      className={
                                        this.state.col4
                                            ? "mdi mdi-chevron-up float-end"
                                            : "mdi mdi-chevron-down float-end"
                                      }
                                  ></i>
                                </h6>
                              </CardHeader>
                            </Link>
                            <Collapse isOpen={this.state.col4}>
                              <CardBody>
                                <p className="text-muted mb-0 faq-ans">
                                  Tu pedido será asignado a un conductor o repartidor cercano, quien se encargará de completar el servicio de manera rápida y segura.
                                </p>
                              </CardBody>
                            </Collapse>
                          </Card>
                        </div>
                      </div>

                      <div className="mt-3">
                        <Link to="#" className="btn btn-primary mt-2 me-2">
                          Aceptar
                        </Link>{" "}
                        <Link to="#" className="btn btn-outline-primary mt-2">
                          Rechazar
                        </Link>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </React.Fragment>
    );
  }
}
export default PageTerms;