import React, {Component} from "react";
import {Alert, Button, Col, Container, Form, Row,} from "reactstrap";
import {Link} from "react-router-dom";

//Import Icons

// Modal Video
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

//Import Images
import hero from "../../assets/images/course/online/hero.jpg";

class Section extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }

    openModal() {
        this.setState({isOpen: true});
    }
    scrollToSection = () => {
        const section = document.getElementById("courses-section");
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };

    render() {
        return (
            <React.Fragment>
                <section className="bg-half-170">
                    <Container>
                        <Row className="mt-5">
                            <Container>
                                <Row className="align-items-center">
                                    <Col lg={7} md={6} xs={12}>
                                        <div className="title-heading me-lg-4">
                                            <Alert
                                                color="primary"
                                                className="alert-pills shadow"
                                                role="alert"
                                            >
                          <span className="content">
                            {" "}
                              Estas listo para apreder y crecer con InnovateX?
                          </span>
                                            </Alert>

                                            <h1 className="heading mb-3">
                                                Formacion 4.0: Domina las habilidades que el mundo digital
                                                demanda.<br/></h1>
                                            <p className="para-desc text-muted">
                                                Únete al programa que combina educación de calidad, innovación
                                                tecnológica y herramientas prácticas para liderar en la economía
                                                digital.
                                            </p>
                                            <div className="subcribe-form mt-4 pt-2">
                                                <Form className="m-0">
                                                    <div className="">
                                                        <Button color="primary" type="button" onClick={this.scrollToSection}>
                                                            Que puedo aprender?
                                                        </Button>
                                                        <br/>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col
                                        lg={5}
                                        md={6}
                                        xs={12}
                                        className="mt-4 pt-2 mt-sm-0 pt-sm-0"
                                    >
                                        <div className="position-relative">
                                            <img
                                                src={hero}
                                                className="rounded img-fluid mx-auto d-block"
                                                alt="Landrick"
                                            />
                                            <div className="play-icon">
                                                <Link
                                                    to="#"
                                                    onClick={this.openModal}
                                                    className="play-btn lightbox border-0"
                                                >
                                                    <i className="mdi mdi-play text-primary rounded-circle shadow"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Row>
                    </Container>
                    <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="yba7hPeTSjk"
                        onClose={() => this.setState({isOpen: false})}
                    />
                </section>
            </React.Fragment>
        );
    }
}

export default Section;
