import React, {Component} from "react";

//Import Components
import Section from "./Section";
import Courses from "./Courses";
import About from "./About";
import Cta from "./Cta";
import Testimonials from "./Testimonials";
import Popup from "../../components/Layout/popup";
import Pricing from "./Pricing";
import {initializePixel, sendMetaPixelEvent} from "../../components/Shared/services/MetaApiService";
import {baseGatewayUrl, formacion40PixelId} from "../../common/data/landing";

class Index extends Component {
    const
    base_url = baseGatewayUrl + '/nova-api/payments';
    plans;

    constructor(props) {
        super(props);
        this.state = {
            plans: null,
        };
    }

    // Make sure to remove the DOM listener when the component is unmounted.
    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    fetchPlans = async () => {
        const response = await fetch(`${this.base_url}/plans`);
        if (!response.ok) {
            throw new Error("Error al obtener los planes");
        }
        const plans = await response.json();
        this.plans = plans;
        this.setState({plans});
        console.log(plans);
    };


    scrollNavigation = () => {
        var doc = document.documentElement;
        const navBar = document.getElementById("topnav");
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (navBar != null) {
            if (top > 80) {
                navBar.classList.add("nav-sticky");
            } else {
                navBar.classList.remove("nav-sticky");
                document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
                document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
                document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
            }
        }
    };

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const fbclid = urlParams.get('fbclid');
        if (fbclid) {
            sessionStorage.setItem('fbclid', fbclid);
            document.cookie = `fbclid=${fbclid}; path=/; max-age=31536000`; // Guardar por 1 año
        }
        initializePixel();
        this.fetchPlans().then((plans) => {
            this.plans = plans;
        })

        const boldOrderId = urlParams.get('bold-order-id');
        const boldTxStatus = urlParams.get('bold-tx-status');

        if (boldOrderId && boldTxStatus) {
            const orderData = JSON.parse(localStorage.getItem(boldOrderId));
            if (orderData) {
                orderData.pixelId = formacion40PixelId;
                console.log('Order Data:', orderData);
                fetch(`${this.base_url}/sales`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(orderData),
                })
                    .then(response => response.json())
                    .then(data => console.log('Success:', data))
                    .catch(error => console.error('Error:', error));
            } else {
                console.warn('No order data found for order ID:', boldOrderId);
            }
        }

        sendMetaPixelEvent('PageView');

        document.body.classList = "";
        document.getElementById("buyButton")?.classList.add("nav-light");
        document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    render() {
        const {plans} = this.state;

        return (
            <React.Fragment>
                <Section/>

                <Courses/>

                <section className="section">
                    <About/>

                    {/*  <PopularCourses />*/}

                    <Cta/>

                    {plans && <Pricing plans={plans}/>}

                    <Testimonials/>

                    {/*<Blog />*/}
                </section>
                <Popup/>
            </React.Fragment>
        );
    }
}

export default Index;
